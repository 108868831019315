<template>
  <div class="itemwiseEntry container">

	<p class="h6 text-center text-primary"><b>{{ item1 }} Inventory Entry</b></p>    
	<br>

	<form class="form-inline">
		<div class="form-group">
				<label class="font-weight-bold mr-1" for="todate">Date:</label>
				<datepicker v-model="todate" name="todate" @closed="serverCall('getItemwiseInventory')"
				:disabled-dates="state.disabledDates"></datepicker>
		</div>
		<div>
			<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
			<scale-loader :loading="loading"></scale-loader>
		</div>
	</form>		
	<p class="text-danger" align="left">{{alert}}</p>

	<vue-good-table
		ref="entryTable"
		:columns="columns"
		:rows="rows"
		:sort-options="{enabled: false,}"
		styleClass="vgt-table condensed"
	>
		<template slot="table-column" slot-scope="props">
        	<span v-if="props.column.in_out === 'In'">
          		{{props.column.label}}<br>In
        	</span>
        	<span v-else-if="props.column.in_out === 'Out'">
          		{{props.column.label}}<br>Out
        	</span>
        	<span v-else>{{props.column.label}}</span>
      	</template>

		<template slot="table-row" slot-scope="props">
			<span v-if="props.column.in_out == 'In' || props.column.in_out == 'Out'">
				<form>
					<input class="form-control" type="number" 
						style="font-weight: bold; color: blue;"
						v-model="props.row[props.column.field]"
						@change="changeCell(props.row[props.column.field], props.column.field, props.row)"
					>
				</form>
			</span>
		</template>

	</vue-good-table>


	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"

export default {
	name: 'itemwiseEntry',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,
			alert: null,
			loading: false,

			todate: null,
			item1: this.$route.params.item1,

			options: [],

			state: {
				// Allow entry for 
				// Current Date - 60 days ---> Curent Date
				disabledDates: {
					to: new Date(new Date().getTime()-(60*24*60*60*1000)),
					from: new Date(),
				}
			},

			rows: [],
			dataList: [],

			columns: [],

			rowModified: {},
		
		};
	},

	computed: {
	},

	mounted(){
		// todate = current date
		this.orgId = this.$route.params['orgId']
		this.todate = new Date()
		this.item1 = this.$route.params['item1']
		this.serverCall('getItemwiseInventory')	
	},

	watch: {
		$route(to, from) {
			this.serverCall('getItemwiseInventory')	
		}
	},

	methods: {
		changeCell(changedData, column, row) {
			// Parse column to get location
			var partsColumn = column.split('_')
			if (partsColumn.length != 2){
				this.alert = "Invalid column"
				return
			}
			var loc = partsColumn[0]
			var inQty = row[loc + "_in"]
			var outQty = row[loc + "_out"]

			this.rowModified = {
									'date': this.todate,
									'location': loc,
									'item1': this.item1,
									'item2': row.item2,
									'in': inQty,
									'out': outQty 
							   }

			this.serverCall('updateInventory')	
        },

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getItemwiseInventory":
								self.getItemwiseInventory(idToken, this.item1, this.todate)
								break;

							case "updateInventory":
								self.updateInventory(idToken, this.rowModified)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Fetch Inventory for the date
        async getItemwiseInventory(authToken, item1, date) {
            var self = this
			self.alert = null
 
             if (!item1 || !date){
                self.alert = "Either date or item1 not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Inventory..."

			var postJson = {
				'item1': item1,
				'toDate': this.getMidDay(date)
			}

			await axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetItemwiseInventory/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getItemwiseInventory - error")
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.iList
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

        // Update Inventory with modified values
        async updateInventory(authToken, rowModified) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Updating Inventory..."

			var postJson = {
				'date': this.getMidDay(rowModified.date), 
				'location': rowModified.location,
				'item1': rowModified.item1,
				'item2': rowModified.item2,
				'in': rowModified.in,
				'out': rowModified.out,
			}

			await axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/UpdateInventory/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(async function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("updateInventory - error")
					}
					else {
						//self.rows = response.data.iList
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

	},

}
</script>

<style>
	.disabled{
		disabled: true;
	}

	.black {
		color: yellow;
		background-color: #dddddd;
	}

	.blue {
		background-color: #F0F8FF;
	}

input {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

</style>

